import JSZip from 'jszip'
import { Dispatch, SetStateAction, } from 'react'
import { TTeethStepsPosition } from './ModelLoader'
import { TSteppedModelData, TTeethModelData } from '../types'
import JSZipUtils from '../Utils/jszip-utils'
import unzipSmilewrapper from './unzipSmilewrapper'

export type TSmilewrapperModelDataHandlers = {
    url: string
    setTeethModelData               : Dispatch< SetStateAction< TTeethModelData        >>
    setGingivaModelData             : Dispatch< SetStateAction< TSteppedModelData      >>
    setTeethModelStepTransformation : Dispatch< SetStateAction< TTeethStepsPosition[][]>>
    setPreloaderVisible             : Dispatch< SetStateAction< boolean                >>
    setSmilewrapperInfo             : Dispatch< SetStateAction< string | undefined     >>
}


const  getSmilewrapperModelsData = (handlers: TSmilewrapperModelDataHandlers) =>{
    const { url } = handlers

    JSZipUtils.getBinaryContent(url, (err: any, data:ArrayBuffer) => {
                
        if(err) {
            console.log('CANT GET ZIP FILE')
            throw err; // or handle err
        }

        // UN-ZUP
        JSZip.loadAsync(data)
        .then((openedArchive:JSZip) => {
            
            unzipSmilewrapper(openedArchive, handlers)

        })
        
    })

}

export default getSmilewrapperModelsData
