import React, {useState} from "react";
import WasmTpviewer from "./WasmTpviewer";
import JsTpviewer from "./JsTpviewer";

type TpviewerImplementation = 'js' | 'wasm';

const getImplSearchParam = (): TpviewerImplementation | undefined => {
    const impl = new URL(window.location.href).searchParams.get('impl');
    if (impl === 'js' || impl === 'wasm') {
        return impl as TpviewerImplementation;
    }
    return undefined
}

const TpviewerSwitcher = () => {
    const [tpviewerImplementation, setTpviewerImplementation] = useState<TpviewerImplementation>(getImplSearchParam() || 'wasm')

    const onWasmCrash = () => {
        console.warn('Switching to js implementation')
        setTpviewerImplementation('js')
    }

    return <>
        {tpviewerImplementation === 'wasm' && <WasmTpviewer onCrash={onWasmCrash}/>}
        {tpviewerImplementation === 'js' && <JsTpviewer/>}
    </>

}

export default TpviewerSwitcher;
