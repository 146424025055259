import { useThree } from "@react-three/fiber"
import * as THREE from 'three'
import { useEffect } from "react"
import { TViewValue } from "../types"
import getZoomValue from "../Utils/get-viewport-zoom-value"
import { TApplicationActiveTab } from "../JsTpviewer"

type TViewTransformatorProps = {
    activeTab?: TApplicationActiveTab  
    currentView: TViewValue
    isViewClicked: boolean
    setCameraParameters?: (x:number,y:number,z: number, zoom: number) => void
}

const setViewVisibility = (currentView: TViewValue, sceneObjects: THREE.Object3D[]) => {

    sceneObjects.forEach( model => model.visible = true )

    switch(currentView){
        case "top":
            sceneObjects.forEach( (model) => {
                if(model.name.indexOf('lower')>-1){
                    model.visible = false
                }
                if(model.name.indexOf('-3')>-1 || model.name.indexOf('-4')>-1){
                    model.visible = false
                }
            } )
            break
        case "bottom":
            sceneObjects.forEach( (model) => {
                if(model.name.indexOf('upper')>-1){
                    model.visible = false
                }
                if(model.name.indexOf('-1')>-1 || model.name.indexOf('-2')>-1){
                    model.visible = false
                }
            } )
            break
        default:
            break
    }

}

const ViewTransformator = ( props: TViewTransformatorProps ) => {
    
    const { currentView, isViewClicked, activeTab, setCameraParameters } = props
    
    const { camera, scene } = useThree(state => state)
    const { children } = scene

    useEffect(()=>{
        camera.zoom = getZoomValue(activeTab)
        camera.updateProjectionMatrix()
        switch(currentView){
            case 'front':
                camera.position.copy(new THREE.Vector3(0,0,100))
                if(setCameraParameters){
                    setCameraParameters(
                        camera.position.x,
                        camera.position.y,
                        camera.position.z,
                        camera.zoom,
                    )
                }
                setViewVisibility(currentView, children)
                break
            case 'top':
                camera.position.copy(new THREE.Vector3(0,-100,0))
                if(setCameraParameters){
                    setCameraParameters(
                        camera.position.x,
                        camera.position.y,
                        camera.position.z,
                        camera.zoom,
                    )
                }
                setViewVisibility(currentView, children)
                break
            case 'bottom':
                camera.position.copy(new THREE.Vector3(0,100,0))
                if(setCameraParameters){
                    setCameraParameters(
                        camera.position.x,
                        camera.position.y,
                        camera.position.z,
                        camera.zoom,
                    )
                }
                setViewVisibility(currentView, children)
                break
            case 'left':
                camera.position.copy(new THREE.Vector3( 100,0,0))
                if(setCameraParameters){
                    setCameraParameters(
                        camera.position.x,
                        camera.position.y,
                        camera.position.z,
                        camera.zoom,
                    )
                }
                setViewVisibility(currentView, children)
                break
            case 'right':
                camera.position.copy(new THREE.Vector3(-100,0,0))
                if(setCameraParameters){
                    setCameraParameters(
                        camera.position.x,
                        camera.position.y,
                        camera.position.z,
                        camera.zoom,
                    )
                }
                setViewVisibility(currentView, children)
                break
            default:
                break
        }

        

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentView, isViewClicked])

    
    return(<></>)
}

export default ViewTransformator
