import { Dispatch, SetStateAction, } from 'react'
import { TSteppedModelData } from '../types'
import axios from 'axios'
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader"
import { Mesh } from 'three'
import { mergeBufferGeometries } from './BufferGeometryUtils'
type TObjModelDataHandlers = {
    url: string
    setTeethModelGeometry           : Dispatch< SetStateAction< TSteppedModelData      >>
    setGingivaModelData             : Dispatch< SetStateAction< TSteppedModelData      >>
    setPreloaderVisible             : Dispatch< SetStateAction< boolean                >>
}

export type TTpJsonFileDescription = {
    fileName : string
    hashSum  : string
    size     : string
}

export type TTpJsonFile = {
    caseID         : string
    computerName   : string
    numberOfStages : string
    timeStamp      : string
    userName       : string
    files          : TTpJsonFileDescription[]
}

type TStageObj = {
    stageIndex: number
    objData   : Mesh[]
}

type TPSteppedGeometry = {
    gingiva:TSteppedModelData
    teeth:TSteppedModelData
}



const getSteppedGeometryFromObj = (steppedObj:TStageObj[]):TPSteppedGeometry => {
    const steppedGeometry:TPSteppedGeometry = {
        gingiva:{
            upperSteps:[],
            lowerSteps:[],
        },
        teeth:{
            upperSteps:[],
            lowerSteps:[],
        }
    }

    steppedObj.forEach( (stepObj,objIndex) => {
        
        const upperTeethMergedGeometry = mergeBufferGeometries(
            stepObj.objData
            .filter((objItem)=>{
                const name = objItem.name.toLowerCase()
                return( name.indexOf('tooth')>-1 && (name.indexOf(' 3')>-1 || name.indexOf(' 4')>-1) )
            })
            .map(objItem => objItem.geometry)
        )

        const lowerTeethMergedGeometry = mergeBufferGeometries(
            stepObj.objData
            .filter((objItem)=>{
                const name = objItem.name.toLowerCase()
                return( name.indexOf('tooth')>-1 && (name.indexOf(' 1')>-1 || name.indexOf(' 2')>-1) )
            })
            .map(objItem => objItem.geometry)
        )

        const upperGingivaGeometry = mergeBufferGeometries(
            stepObj.objData
            .filter((objItem)=>{
                const name = objItem.name.toLowerCase()
                return( name.indexOf('maxilla')>-1 )
            })
            .map(objItem => objItem.geometry)
        )

        const lowerGingivaGeometry = mergeBufferGeometries(
            stepObj.objData
            .filter((objItem)=>{
                const name = objItem.name.toLowerCase()
                return( name.indexOf('mandible')>-1 )
            })
            .map(objItem => objItem.geometry)
        )

        steppedGeometry.teeth.upperSteps.push({
            name: `tooth-${objIndex}-upper`,
            data: upperTeethMergedGeometry
        })

        steppedGeometry.teeth.lowerSteps.push({
            name: `tooth-${objIndex}-lower`,
            data: lowerTeethMergedGeometry
        })

        steppedGeometry.gingiva.upperSteps.push({
            name: `gingiva-${objIndex}-upper`,
            data: upperGingivaGeometry
        })

        steppedGeometry.gingiva.lowerSteps.push({
            name: `gingiva-${objIndex}-lower`,
            data: lowerGingivaGeometry
        })
    })


    return(steppedGeometry)
}

const getObjModelsData = (handlers: TObjModelDataHandlers) => {

    const {
        url,
        setTeethModelGeometry,
        setGingivaModelData,
        setPreloaderVisible
    } = handlers

    axios.get<TTpJsonFile>(url)
    .then((response)=>{
        const objArray:TStageObj[] = []
        const fileFolderUrl = url.split('/').slice(0,url.split('/').length-1).join('/')
        
        Promise.all(
            response.data.files
            .map(fileItem => fileItem.fileName)
            .sort((a, b) => {
                if(a < b) { return -1 }
                if(a > b) { return  1 }
                return 0
            })
            .map(
                (fileName, fileIndex) => {
                    console.log(`${fileFolderUrl}/${fileName}`)
                    return(
                        new OBJLoader().loadAsync(`${fileFolderUrl}/${fileName}`, (e)=>{/* onProgress */ })
                        .then((loadedObj:any)=>{
                            objArray.push({
                                stageIndex: fileIndex,
                                objData:loadedObj.children as Mesh[]
                            })
                        })
                    )
                }
            )
        )
        .then(()=>{
            
            const steppedModelData = getSteppedGeometryFromObj(
                objArray.sort((a,b)=>{
                    return(a.stageIndex-b.stageIndex)
                })
            )
            
            setTeethModelGeometry(
                steppedModelData.teeth
            )

            setGingivaModelData(
                steppedModelData.gingiva
            )
            
            setPreloaderVisible(false)
        })
        .catch((error)=>{
            console.log('CANT PARSE UNZIPPED DATA FROM CTM FILE', JSON.stringify(error))
        })
        
    })
    .catch((error)=>{
        console.log('CANT GET TreatmentPlan JSON File')
    })


}

export default getObjModelsData
