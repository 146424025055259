// @ts-ignore
import ioFiles from 'tpviewer-wasm/io_files'
// @ts-ignore
import resize from 'tpviewer-wasm/resize'
// @ts-ignore
import popups from 'tpviewer-wasm/popups'
// @ts-ignore
import resetMouseEvents from 'tpviewer-wasm/reset_mouse_events'
import 'tpviewer-wasm/styles.css'
// @ts-ignore
import tpViewerData from 'tpviewer-wasm/tpviewer.data'
// @ts-ignore
import tpViewerWasmBinary from 'tpviewer-wasm/tpviewer.wasm'
// @ts-ignore
import tpviewerWasm from 'tpviewer-wasm/tpviewer'
import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import Preloader from "./Ui/Preloader";

const TP_VIEWER_FILES_MAPPING: { [key: string]: string } = {
    'tpviewer.data': tpViewerData as string,
    'tpviewer.wasm': tpViewerWasmBinary as string
}

type WasmTpviewerProps = {
    onCrash: () => void;
}

const WasmTpviewer = ({onCrash}: WasmTpviewerProps) => {
    let [canvasRef] = useState(React.createRef<HTMLCanvasElement>());
    let [showCanvas, setShowCanvas] = useState(false);

    useEffect(() => {
        canvasRef.current!.addEventListener("webglcontextlost", (e => {
                alert("WebGL context lost. You will need to reload the page.");
                e.preventDefault();
            }
        ), false);

        window.onerror = e => {
            console.error(e);
            onCrash();
        }

        (window as any).Module = {
            locateFile: function (path: string, _prefix: string): string {
                return TP_VIEWER_FILES_MAPPING[path] || path
            },
            preRun: [],
            postRun: () => {
                setShowCanvas(true);
                (window as any).registerResize();
                (window as any).updateCalculateMouseEvent();
            },
            onAbort: (reason: string) => {
                console.error(`Failed to initialize tpviewer-wasm due to error: ${reason}`)
                onCrash()
            },
            canvas: canvasRef.current,
            totalDependencies: 0,
            monitorRunDependencies: function (n: number) {
                this.totalDependencies = Math.max(this.totalDependencies, n);
            }
        };
    }, [onCrash]);

    return (
        <>
            { !showCanvas &&  <Preloader /> }
            <div>
                <Helmet>
                    <script type="text/javascript" src={popups}></script>
                    <script type="text/javascript" src={ioFiles}></script>
                    <script type="text/javascript" src={resetMouseEvents}></script>
                    <script type="text/javascript" src={resize}></script>
                    <script type="text/javascript" src={tpviewerWasm}></script>
                </Helmet>
                <canvas
                    style={{
                        display: showCanvas ? "block" : "none"
                    }}
                    className="emscripten"
                    id="canvas"
                    ref={canvasRef}
                    tabIndex={-1}
                ></canvas>
            </div>
        </>
    )
}

export default WasmTpviewer
