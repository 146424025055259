import * as THREE from 'three'
import { TModelData } from "../../types"

type TGingivaProps = {
    modelName: string
    modelData:TModelData[],
}

export const gingivaMaterial = new THREE.MeshPhongMaterial({
    color: 0xff8484,
    shininess: 25,
    specular: 0x111111,
})

const Gingiva = ( props:TGingivaProps ) => {
    
    const { modelData, modelName } = props
    
    return(
        <group
            name={ modelName }
        >
            {
                modelData.map((modelItem, modelIndex)=>{

                    const { name, data } = modelItem
                    let   newModelName        = name
                    const splittedName = name.split('_')
                    if(splittedName.length === 3){


                        const splittedFileName = splittedName[2].split('.')
                        
                        newModelName = `teeth-${splittedFileName[0]}`
                    }

                    return(
                        <mesh
                            name={ newModelName }
                            key={`${modelName}-item-${modelIndex}`}
                            geometry={ data }
                            material={ gingivaMaterial }
                        />
                    )
                })
            }
        </group>
    )
}

export default Gingiva
