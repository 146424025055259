import { TApplicationActiveTab } from "../JsTpviewer"

const getZoomValue = ( activeTab?: TApplicationActiveTab ) =>{

    const maxWidth480 = window.matchMedia('(max-width: 480px)').matches
    const maxHeight991 = window.matchMedia('(max-height: 991px)').matches
    const maxWidth930 = window.matchMedia('(max-width: 930px)').matches
    const maxHeight480 = window.matchMedia('(max-height: 480px)').matches

    if(maxWidth480 && maxHeight991){
        // MOBILE VERTICAL
        return 4
    }

    if(maxWidth930 && maxHeight480){
        // MOBILE HORIZONTAL
        return 4
    }
    return 6
}

export default getZoomValue
