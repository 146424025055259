import { useEffect, Dispatch, SetStateAction } from "react"
import * as THREE from 'three'
import { TSteppedModelData, TTeethModelData } from '../types'
import getSmilewrapperModelsData from './getSmilewrapperModelsData'
import getObjModelsData          from './getObjModelsData'

type TModelLoaderProps = {
    url: string
    setTeethModelData               : Dispatch< SetStateAction< TTeethModelData        >>
    setGingivaModelData             : Dispatch< SetStateAction< TSteppedModelData      >>
    setTeethModelGeometry           : Dispatch< SetStateAction< TSteppedModelData      >>
    setTeethModelStepTransformation : Dispatch< SetStateAction< TTeethStepsPosition[][]>>
    setPreloaderVisible             : Dispatch< SetStateAction< boolean                >>
    setSmilewrapperInfo             : Dispatch< SetStateAction< string | undefined     >>
}

export type TTeethStepsPosition = {
    id: string
    position: THREE.Vector3
    rotationMatrix: THREE.Matrix3
}


const ModelLoader = (props: TModelLoaderProps) => {
    const {
        url,
        setTeethModelData,
        setGingivaModelData,
        setTeethModelStepTransformation,
        setPreloaderVisible,
        setTeethModelGeometry,
        setSmilewrapperInfo,
    } = props

    useEffect(()=>{

        if( url ){
            
            // ZIP with smilewrapper format inside
            if(url.indexOf('.zip')>-1){

                getSmilewrapperModelsData({
                    url,
                    setTeethModelData,
                    setGingivaModelData,
                    setTeethModelStepTransformation,
                    setPreloaderVisible,
                    setSmilewrapperInfo
                })
            }

            // CASE WITH JSON AND OBJ-FILES BESIDE
            if(url.indexOf('.json')>-1){

                getObjModelsData({
                    url,
                    setGingivaModelData,
                    setPreloaderVisible,
                    setTeethModelGeometry
                })

            }
        }
    // eslint-disable-next-line
    },[url])

    return(
        <>
        </>
    )
}

export default ModelLoader
