import { TApplicationActiveTab } from "../JsTpviewer"
import CompareIcon from "./icons/CompareIcon"

type TSwitchBeforeAfterProps = {
    activeTab: TApplicationActiveTab
    setActiveTab:(value: React.SetStateAction<TApplicationActiveTab>) => void
    setStepIndex:(value: React.SetStateAction<number>) => void
}

const SwitchBeforeAfter = (props: TSwitchBeforeAfterProps) => {
    
    const { setActiveTab, setStepIndex, activeTab } = props

    return(
        <div id='compare-before-after'>
            <CompareIcon/>
            <div className='text'>
                Compare before & after
            </div>

            <label className="switch">
                <input 
                    type="checkbox" 
                    checked={ activeTab === 'BEFORE_AFTER' ? true : false }
                    onChange = {(e) => {
                        if(activeTab=== 'BEFORE_AFTER'){
                            setActiveTab('TIMELINE')
                            setStepIndex(0)
                        }else{
                            setActiveTab('BEFORE_AFTER')
                        }
                    }}
                />
                <span className="slider round"/>
            </label>
        </div>
    )
}

export default SwitchBeforeAfter
