import { useFrame } from "@react-three/fiber"
import { Vector3 } from "three"

export type TCameraPositionReceiverProps = {
    isNeedToSend   : boolean
    cameraParameters : {
        position: THREE.Vector3,
        zoom: number
    }
}

const CameraPositionSender = (props: TCameraPositionReceiverProps) =>{
    
    const { isNeedToSend, cameraParameters } = props
    
    useFrame(({camera}) => {
            if(isNeedToSend === true){
                camera.position.copy(cameraParameters.position)
                camera.lookAt(new Vector3(0,0,0))
                camera.zoom = cameraParameters.zoom
            }
        })
    return(<></>)
}

export default CameraPositionSender
