import { useFrame } from "@react-three/fiber"
import { Vector3 } from "three"

export type TCameraPositionReceiverProps = {
    isNeedToReceive   : boolean
    setCameraParameters : (x:number,y:number,z: number, zoom: number) => void
}

const CameraPositionReceiver = (props: TCameraPositionReceiverProps) =>{
    
    const { isNeedToReceive, setCameraParameters } = props
    
    useFrame(({camera}) => {
            if(isNeedToReceive === true){
                const newCameraPosition = new Vector3()
                newCameraPosition.copy(camera.position)
                setCameraParameters(
                    newCameraPosition.x, 
                    newCameraPosition.y, 
                    newCameraPosition.z,
                    camera.zoom
                )
            }
        })
    return(<></>)
}

export default CameraPositionReceiver
