import packageInfo from '../../package.json'

type TInfoProps = {
    smilewrapperInfo?: string
}

const Information = (props:TInfoProps) =>{

    const { smilewrapperInfo } = props

    let splittedInfo = String(smilewrapperInfo).split('-') // UUID
    
    let localCaseId = smilewrapperInfo ? ( 
            splittedInfo.length === 5 || splittedInfo.length === 4 ?  // for version uuid.v4 or uuid.v1
                splittedInfo[splittedInfo.length-1] 
            : 
                String(smilewrapperInfo) 
        ) 
    : 
        '-'


    return(
        <div id='information'>
            <div className='info-wrapper'>
                <div className='info-line'>
                    <div className='title'>
                        CaseId:
                    </div>
                    <div className='value'>
                        { localCaseId }
                    </div>
                </div>
                <div className='info-line'>
                    <div className='title'>
                        Version:
                    </div>
                    <div className='value'>
                        { packageInfo.version.replace('-', '.') } js
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Information
