import { Canvas } from '@react-three/fiber'
import { useMemo, useState, useEffect, useRef } from 'react'
import * as THREE from 'three'
import ModelLoader, { TTeethStepsPosition } from "./ModelLoader/ModelLoader"
import { OrbitControls, OrthographicCamera } from '@react-three/drei'
import { TModelData, TSteppedModelData, TTeethModelData, TViewValue } from './types'
import Preloader from './Ui/Preloader'
import ViewControls from './Ui/ViewControls'
import ViewTransformator from './Scene/ViewTransformator'
import Gingiva from './Scene/MeshGeometry/Gingiva'
import Teeths from './Scene/MeshGeometry/Teeths'
import { mergeBufferGeometries } from './ModelLoader/BufferGeometryUtils'
import TimeLineControls from './Ui/TimeLineControls'
import getZoomValue from './Utils/get-viewport-zoom-value'
import CameraLightController from './Scene/CameraLightController'
import useInterval from './Utils/use-interval'
import JSZip from 'jszip'
import unzipSmilewrapper from './ModelLoader/unzipSmilewrapper'
import CameraPositionReceiver from './Scene/CameraPositionReceiver'
import CameraPositionSender from './Scene/CameraPositionSender'
import Information from './Ui/Information'
import SwitchBeforeAfter from './Ui/SwitchBeforeAfter'

export const delayValue = 700

export type TViewBeforeAfter = "" | "BEFORE" | "AFTER"
const getTransformedGeometry = ( transformation: TTeethStepsPosition[], geometryData: TModelData[] ) => {
    const transformedGeometry:THREE.BufferGeometry[] = []

    transformation.forEach( (transformationItem) =>{
                
        const filteredModel = geometryData
            .filter( dataItem => dataItem.name.slice(-6).indexOf( transformationItem.id ) > -1)

        if(filteredModel.length === 1){
            const currentModel = filteredModel[0]
            const modelGeometryData =  currentModel.data.clone()
            const rotationMatrix =  transformationItem.rotationMatrix.clone()
            

            const tMatrix4 = new THREE.Matrix4()
            const rmtx = rotationMatrix.transpose().toArray()
            const tVec = transformationItem.position
            tMatrix4.elements = [
                rmtx[0], rmtx[1], rmtx[2], 0,
                rmtx[3], rmtx[4], rmtx[5], 0,
                rmtx[6], rmtx[7], rmtx[8], 0,
                tVec.x , tVec.y , tVec.z , 1
            ]
            modelGeometryData.applyMatrix4(tMatrix4)
            transformedGeometry.push(modelGeometryData)
        }                    
    })


    return ( mergeBufferGeometries(transformedGeometry))
} 

export type TApplicationMode = 'CASE_FROM_URL_LOADING' | 'CASE_FROM_URL_DONE' | 'CASE_FROM_LOCAL_FILE' | 'CASE_FROM_LOCAL_FILE_PARSING' | 'CASE_FROM_LOCAL_FILE_DONE' 
export type TApplicationActiveTab = 'TIMELINE' | 'BEFORE_AFTER'

const JsTpviewer = () => {
    
    const parsedUrl = new URL(window.location.href).searchParams.get('case')
    const url = parsedUrl !== null ?  parsedUrl : ''
    const [ activeTab, setActiveTab] = useState<TApplicationActiveTab>('TIMELINE')
    const viewForAutoPlay:TViewValue[] = ['front', 'top']
    const [ viewIndex                    , setViewIndex                    ] = useState(0)
    const [ isPreloaderVisible           , setPreloaderVisible             ] = useState(true)
    const [ applicationMode              , setApplicationMode              ] = useState<TApplicationMode>( url ? 'CASE_FROM_URL_LOADING' : 'CASE_FROM_LOCAL_FILE')
    const [ stepIndex                    , setStepIndex                    ] = useState(0)
    const [ isPlayed                     , setIsPlayed                     ] = useState(false) 
    const [ currentView                  , setCurrentView                  ] = useState<TViewValue>('front')
    const [ isViewClicked                , setViewClicked                  ] = useState(false) 
    const [ teethModelStepTransformation , setTeethModelStepTransformation ] = useState<TTeethStepsPosition[][]>([])
    const [ delay, setDelay] = useState<null|number>(null) // interval size

    const [ isNeedToSyncBeforeAfter      , setNeedToSyncBeforeAfter        ] = useState(false)
    const [ activeBeforeAfterView        , setBeforeAfterActiveView        ] = useState<TViewBeforeAfter>('')
    const [                              , setMouseMove                    ] = useState(false)
    
    const cameraPosition = useRef({
        position: new THREE.Vector3(0,0,100),
        zoom: getZoomValue(),
    })

    const setCameraParameters = (x:number, y:number, z:number, zoom: number) =>{
        cameraPosition.current = {
            position: new THREE.Vector3(x,y,z),
            zoom
        }
    }
    const [ pauseDelay, setPauseDelay] = useState<null|number>(null) // interval size
    // teeths have base geometry for all steps, but transformed for every step
    const [ teethModelData, setTeethModelData ] = useState<TTeethModelData>({
        upper: [],
        lower: [],
    })

    const [ teethModelGeometry, setTeethModelGeometry ] = useState<TSteppedModelData>({
        upperSteps: [],
        lowerSteps: []
    })

    // gingiva have own geometry for every step
    const [ gingivaModelGeometry, setGingivaModeGeometry ] = useState<TSteppedModelData>({
        upperSteps: [],
        lowerSteps: []
    })

    const [smilewrapperInfo, setSmilewrapperInfo         ] = useState<string | undefined>()


    useEffect(()=>{
        const teethsteppedGeometry:TSteppedModelData = {
            upperSteps: [],
            lowerSteps: []
        }

        if(teethModelStepTransformation.length > 0){
            
            teethModelStepTransformation.forEach((stepTransformation, stepIndex)=>{
                
                teethsteppedGeometry.upperSteps.push({
                    name: `teeth-stage${stepIndex}-upper`,
                    data: getTransformedGeometry( stepTransformation,teethModelData.upper )
                })
                
                teethsteppedGeometry.lowerSteps.push({
                    name: `teeth-stage${stepIndex}-lower`,
                    data: getTransformedGeometry( stepTransformation,teethModelData.lower )
                })

            })

            setTeethModelGeometry(teethsteppedGeometry)
        }

        
    },[teethModelData, teethModelStepTransformation])
    

    // ANIMATION START
    useInterval(()=>{
        if(isPlayed === true && stepIndex + 1 < gingivaModelGeometry.upperSteps.length){
            setStepIndex (stepIndex + 1)
        }else{
            if(viewIndex>-1 && isPlayed === true && stepIndex === gingivaModelGeometry.upperSteps.length-1 ){
                if(viewIndex < viewForAutoPlay.length-1){
                    setPauseDelay(2000)
                    setDelay(null)
                }else{
                    setCurrentView(viewForAutoPlay[0])
                    setDelay(null)
                    setIsPlayed(false)
                }
            }else{
                setDelay(null)
                setIsPlayed(false)
            }
        }
    }, delay)

    useInterval(()=>{
        setViewIndex(viewIndex+1)
        setPauseDelay(null)
    }, pauseDelay)

    useEffect(()=>{
        if(viewIndex>-1 && viewIndex <= viewForAutoPlay.length-1){
            setCurrentView(viewForAutoPlay[viewIndex])
            setStepIndex(0)
            setDelay(delayValue)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[viewIndex])
    // ANIMATION END

    const cancelStartupAnimation = () =>{
        setPauseDelay(null)
        setDelay(null)
        setIsPlayed(false)
        setViewIndex(-1)
    }

    const teethsModels = useMemo(()=>{
        if(teethModelGeometry.upperSteps.length > 0 && teethModelGeometry.lowerSteps.length > 0){
            
            return(
                <>
                    <Teeths 
                        modelName={ 'teeths-upper' }
                        modelData={ [teethModelGeometry.upperSteps[stepIndex]] }
                    />
                    <Teeths 
                        modelName={ 'teeths-lower' }
                        modelData={ [teethModelGeometry.lowerSteps[stepIndex]] }
                    />

                </>
            )
        }
        return undefined
    },[teethModelGeometry, stepIndex])

    const teethsModelsBefore = useMemo(()=>{
        if(teethModelGeometry.upperSteps.length > 0 && teethModelGeometry.lowerSteps.length > 0){
            
            return(
                <>
                    <Teeths 
                        modelName={ 'teeths-upper' }
                        modelData={ [teethModelGeometry.upperSteps[0]] }
                    />
                    <Teeths 
                        modelName={ 'teeths-lower' }
                        modelData={ [teethModelGeometry.lowerSteps[0]] }
                    />

                </>
            )
        }
        return undefined
    },[teethModelGeometry])

    const teethsModelsAfter = useMemo(()=>{
        if(teethModelGeometry.upperSteps.length > 0 && teethModelGeometry.lowerSteps.length > 0){
            
            return(
                <>
                    <Teeths 
                        modelName={ 'teeths-upper' }
                        modelData={ [teethModelGeometry.upperSteps[teethModelGeometry.upperSteps.length-1]] }
                    />
                    <Teeths 
                        modelName={ 'teeths-lower' }
                        modelData={ [teethModelGeometry.lowerSteps[teethModelGeometry.lowerSteps.length-1]] }
                    />

                </>
            )
        }
        return undefined
    },[teethModelGeometry])

    const gingivaModels = useMemo(()=>{
        if(gingivaModelGeometry.upperSteps.length > 0 && gingivaModelGeometry.lowerSteps.length > 0){
            
            return(
                <>
                    <Gingiva 
                        modelName={ 'gingiva-upper' }
                        modelData={ [gingivaModelGeometry.upperSteps[stepIndex]] }
                    />
                    <Gingiva 
                        modelName={ 'gingiva-lower' }
                        modelData={ [gingivaModelGeometry.lowerSteps[stepIndex]] }
                    /> 
                </>
            )
        }
        return undefined
    },[gingivaModelGeometry, stepIndex])


    const gingivaModelsBefore = useMemo(()=>{
        if(gingivaModelGeometry.upperSteps.length > 0 && gingivaModelGeometry.lowerSteps.length > 0){
            
            return(
                <>
                    <Gingiva 
                        modelName={ 'gingiva-upper' }
                        modelData={ [gingivaModelGeometry.upperSteps[0]] }
                    />
                    <Gingiva 
                        modelName={ 'gingiva-lower' }
                        modelData={ [gingivaModelGeometry.lowerSteps[0]] }
                    /> 
                </>
            )
        }
        return undefined
    },[gingivaModelGeometry])

    const gingivaModelsAfter = useMemo(()=>{
        if(gingivaModelGeometry.upperSteps.length > 0 && gingivaModelGeometry.lowerSteps.length > 0){
            
            return(
                <>
                    <Gingiva 
                        modelName={ 'gingiva-upper' }
                        modelData={ [gingivaModelGeometry.upperSteps[gingivaModelGeometry.upperSteps.length-1]] }
                    />
                    <Gingiva 
                        modelName={ 'gingiva-lower' }
                        modelData={ [gingivaModelGeometry.lowerSteps[gingivaModelGeometry.lowerSteps.length-1]] }
                    /> 
                </>
            )
        }
        return undefined
    },[gingivaModelGeometry])

    useEffect(()=>{

        if( isPreloaderVisible === false){
            setApplicationMode('CASE_FROM_URL_DONE')
        }
        if( applicationMode === 'CASE_FROM_LOCAL_FILE_DONE' || applicationMode === 'CASE_FROM_URL_DONE'){
            setDelay(delayValue)
            setIsPlayed(true)
        }
    },[applicationMode, isPreloaderVisible])
    

    const onWheelHandler = (activeBeforeAfterView: TViewBeforeAfter) =>{
        setNeedToSyncBeforeAfter(true)
        setBeforeAfterActiveView( activeBeforeAfterView )
        setMouseMove(e => !e)
        setTimeout(()=>{
            setMouseMove(e => !e)
        },50)
        setTimeout(()=>{
            setMouseMove(e => !e)
            setNeedToSyncBeforeAfter(false)
        },100)
    }

    const manageBeforeAfterSync = ( viewToSync: TViewBeforeAfter, isNeedToSync: boolean ) =>{

            setBeforeAfterActiveView(viewToSync) 
            setNeedToSyncBeforeAfter(isNeedToSync)

    }

    const SyncCamera = useMemo(()=>{
        
        return(
            <OrthographicCamera 
                makeDefault
                position = { new THREE.Vector3( 0,0, 100) }
                zoom     = { getZoomValue(activeTab)      }
            />
        )
    },[activeTab])

    // disable startup animation if before/after was enabled
    useEffect(()=>{
        if(isPlayed===true){
            cancelStartupAnimation()
        }
    },[activeTab])

    return (
        <>
            
            {
                applicationMode === 'CASE_FROM_URL_LOADING' &&
                <>
                    <Preloader/>
                    <ModelLoader 
                        url                              = { url                             }
                        setTeethModelData                = { setTeethModelData               }
                        setGingivaModelData              = { setGingivaModeGeometry          }
                        setTeethModelGeometry            = { setTeethModelGeometry           }         
                        setTeethModelStepTransformation  = { setTeethModelStepTransformation }
                        setPreloaderVisible              = { setPreloaderVisible             }
                        setSmilewrapperInfo              = { setSmilewrapperInfo             }
                    />
                </>
            }

            {
                applicationMode === 'CASE_FROM_LOCAL_FILE_PARSING' &&
                <Preloader/>
            }

            {
                (applicationMode === 'CASE_FROM_LOCAL_FILE') && 
                <div id='file-loader'>
                    <label className="button-load-file">
                        Load 
                        <input 
                            type="file" 
                            style={{display: 'none'}} 
                            required
                            onChange={(e)=>{
                                
                                if(e.target.files && e.target.files[0]){
                                    setApplicationMode('CASE_FROM_LOCAL_FILE_PARSING')

                                    const fileData:File = e.target.files[0]
                                    const zip = new JSZip()
                                    zip.loadAsync(fileData).then((fileContent) => {
                                        unzipSmilewrapper(
                                            fileContent,
                                            {
                                                url: '',
                                                setGingivaModelData: setGingivaModeGeometry,
                                                setPreloaderVisible,
                                                setTeethModelData,
                                                setTeethModelStepTransformation,
                                                setSmilewrapperInfo
                                            },
                                            ()=>{
                                                setApplicationMode('CASE_FROM_LOCAL_FILE_DONE')
                                            }
                                        )
                                    })

                                }
                            }}
                        />
                    </label>
                </div>
            }

            {
                ( applicationMode === 'CASE_FROM_LOCAL_FILE_DONE' || applicationMode === 'CASE_FROM_URL_DONE') && 
                <>  

                    {
                        activeTab==='TIMELINE' &&
                        <>
                            <div id='view-3d'>
                                
                                

                                <Canvas
                                    
                                    gl={{ 
                                        antialias: true,
                                        autoClearColor: true,
                                        toneMapping: THREE.NoToneMapping,
                
                                    }}
                                    legacy
                                    linear

                                    resize = {{ scroll: true, debounce: { scroll: 50, resize: 0 } }}
                                    camera={{
                                        position: new THREE.Vector3(0,0,-10),
                                        zoom: getZoomValue()
                                    }}

                                    orthographic
                                > 
                                    
                                    <OrbitControls 
                                        enablePan={false}
                                        dampingFactor = { 0.65 }
                                    />
                                    
                                    <directionalLight name='light'  color= { 0xffffff } intensity={ 1.0 } position={[0,0,10]} />
                                    <CameraLightController/>

                                    <hemisphereLight 
                                        //args={['#888899', '#333344']} // skyColor={ 0x443333 } groundColor={ 0x111122 } intensity
                                        args={['#161111', '#161111']}
                                        intensity={1}
                                    />

                                    { teethsModels  }

                                    { gingivaModels }

                                    <ViewTransformator 
                                        currentView    = { currentView }
                                        isViewClicked  = { isViewClicked                         } 
                                    />

                                </Canvas>
                                
                                <img id='label-3d' src="3d.png" alt="3d"/>
                                
                                <Information smilewrapperInfo = { smilewrapperInfo }/>

                            </div>
                            
                            <div id='controls'>

                                <ViewControls 
                                    currentView     = { currentView                            }
                                    setCurrentView  = { setCurrentView                         }
                                    setViewClicked  = { setViewClicked                         }  
                                />

                                <TimeLineControls
                                    
                                    currentView     = { currentView                            }
                                    setCurrentView  = { setCurrentView                         }  
                                    setViewClicked  = { setViewClicked                         } 
                                    setStepIndex    = { setStepIndex                           }
                                    setIsPlayed     = { setIsPlayed                            }
                                    isPlayed        = { isPlayed                               }
                                    maxSteps        = { gingivaModelGeometry.upperSteps.length }
                                    currentIndex    = { stepIndex                              }
                                    delay           = { delay                                  }
                                    setDelay        = { setDelay                               }
                                    cancelAnimation = { cancelStartupAnimation                 }
                                />
                                
                                <SwitchBeforeAfter 
                                    activeTab    = { activeTab    }
                                    setActiveTab = { setActiveTab }
                                    setStepIndex = { setStepIndex }
                                />

                            </div>
                        </>
                    }


                    {
                        activeTab==='BEFORE_AFTER' &&
                        <>  
                            
                            <div id='view-before-after'>
                                
                                <div className="canvas-wrapper"

                                >
                                    <div className='title' id='title_before'>
                                        Before
                                    </div>
                                    <Canvas
                                        gl={{ 
                                            antialias: true,
                                            autoClearColor: true,
                                            toneMapping: THREE.NoToneMapping,
                                        }}
                                        
                                        legacy
                                        linear
                                        resize = {{ scroll: true, debounce: { scroll: 50, resize: 0 } }}

                                        onMouseDown   = { ()=>{manageBeforeAfterSync('BEFORE',true  )} }
                                        onMouseUp     = { ()=>{manageBeforeAfterSync(''      ,false )} }
                                        onMouseEnter  = { ()=>{setBeforeAfterActiveView('BEFORE')    } }
                                        onMouseLeave  = { ()=>{setBeforeAfterActiveView('')          } }
                                        onMouseOut    = { ()=>{setBeforeAfterActiveView('')          } }
                                        onMouseMove   = { ()=>{setMouseMove(e => !e)                 } }
                                        onWheel       = { ()=>{onWheelHandler('BEFORE')              } }

                                        onTouchStart  = { ()=>{if(activeBeforeAfterView === ''      ) manageBeforeAfterSync('BEFORE',true  )} }
                                        onTouchEnd    = { ()=>{if(activeBeforeAfterView === 'BEFORE') manageBeforeAfterSync(''      ,false )} }
                                        onTouchCancel = { ()=>{if(activeBeforeAfterView === 'BEFORE') manageBeforeAfterSync(''      ,false )} }
                                        onTouchMove   = { ()=>{setMouseMove(e => !e)                 } }
                                    > 
                                        <OrbitControls
                                            enablePan     = { false                              }
                                            dampingFactor = { 0.65                               }
                                        />
                                        <directionalLight name='light'  color= { 0xffffff } intensity={ 1.0 } position={[0,0,10]} />
                                        <CameraLightController/>

                                        { SyncCamera }

                                        <CameraPositionReceiver
                                            isNeedToReceive = { isNeedToSyncBeforeAfter === true && activeBeforeAfterView ==='BEFORE' }
                                            setCameraParameters={ setCameraParameters }
                                        />
                                        
                                        <CameraPositionSender 
                                            isNeedToSend = { isNeedToSyncBeforeAfter === true && activeBeforeAfterView ==='AFTER' }
                                            cameraParameters = { cameraPosition.current }
                                        />
                                        
                                        <hemisphereLight 
                                            //args={['#888899', '#333344']} // skyColor={ 0x443333 } groundColor={ 0x111122 } intensity
                                            args={['#161111', '#161111']}
                                            intensity={1}
                                        />

                                        { teethsModelsBefore  }

                                        { gingivaModelsBefore }

                                        <ViewTransformator
                                            activeTab           = { activeTab           }
                                            currentView         = { currentView         }
                                            setCameraParameters = { setCameraParameters } 
                                            isViewClicked       = { isViewClicked       }
                                        />

                                    </Canvas>
                                </div>

                                <div className='label-anchor'>
                                    <img id='label-3d' src="3d.png" alt="3d"/>
                                </div>
                                
                                <div className="canvas-wrapper">
                                    <div className='title' id='title_after'>
                                        After
                                    </div>
                                    <Canvas
                                            
                                        gl={{ 
                                            antialias: true,
                                            autoClearColor: true,
                                            toneMapping: THREE.NoToneMapping,
                                        }}

                                        legacy
                                        linear

                                        resize = {{ scroll: true, debounce: { scroll: 50, resize: 0 } }}

                                        onMouseDown   = { ()=>{manageBeforeAfterSync('AFTER' ,true  )} }
                                        onMouseUp     = { ()=>{manageBeforeAfterSync(''      ,false )} }
                                        onMouseEnter  = { ()=>{setBeforeAfterActiveView('AFTER')     } }
                                        onMouseLeave  = { ()=>{setBeforeAfterActiveView('')          } }
                                        onMouseOut    = { ()=>{setBeforeAfterActiveView('')          } }
                                        onMouseMove   = { ()=>{setMouseMove(e => !e)                 } }
                                        onWheel       = { ()=>{onWheelHandler('AFTER')               } }

                                        onTouchStart  = { ()=>{if(activeBeforeAfterView === ''     ) manageBeforeAfterSync('AFTER' ,true  )} }
                                        onTouchEnd    = { ()=>{if(activeBeforeAfterView === 'AFTER') manageBeforeAfterSync(''      ,false )} }
                                        onTouchCancel = { ()=>{if(activeBeforeAfterView === 'AFTER') manageBeforeAfterSync(''      ,false )} }
                                        onTouchMove   = { ()=>{setMouseMove(e => !e)                 } }
                                        
                                    > 

                                        <OrbitControls
                                            enablePan     = {false}
                                            dampingFactor = { 0.65 }
                                        />

                                        { SyncCamera }

                                        <directionalLight name='light'  color= { 0xffffff } intensity={ 1.0 } position={[0,0,100]} />
                                        <CameraLightController/>

                                        <CameraPositionSender 
                                            isNeedToSend = { isNeedToSyncBeforeAfter === true && activeBeforeAfterView ==='BEFORE' }
                                            cameraParameters = { cameraPosition.current }
                                        />

                                        <CameraPositionReceiver
                                            isNeedToReceive     = { isNeedToSyncBeforeAfter === true && activeBeforeAfterView ==='AFTER' }
                                            setCameraParameters = { setCameraParameters }
                                        />

                                        <hemisphereLight 
                                            //args={['#888899', '#333344']} // skyColor={ 0x443333 } groundColor={ 0x111122 } intensity
                                            args={['#161111', '#161111']}
                                            intensity={1}
                                        />

                                        { teethsModelsAfter  }

                                        { gingivaModelsAfter }

                                        <ViewTransformator
                                            activeTab           = { activeTab           }
                                            currentView         = { currentView         }
                                            setCameraParameters = { setCameraParameters }
                                            isViewClicked       = { isViewClicked       } 
                                        />

                                    </Canvas>
                                </div>
                
                            </div>
                            <div id='controls'>

                                <ViewControls 
                                    currentView     = { currentView                            }
                                    setCurrentView  = { setCurrentView                         }
                                    setViewClicked  = { setViewClicked                         }  
                                />

                                <SwitchBeforeAfter 
                                    activeTab    = { activeTab    }
                                    setActiveTab = { setActiveTab }
                                    setStepIndex = { setStepIndex }
                                />
                            </div>
                        </>
                    }

                </>
            }
            
            
        </>
    )
}

export default JsTpviewer
