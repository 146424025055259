import * as THREE from 'three'
import { TModelData } from "../../types"

type TTeethsProps = {
    modelName: string
    modelData:TModelData[],
    opacity  ?: boolean
}

export const toothMaterial = new THREE.MeshPhongMaterial({
    color: 0xdddddd,
    shininess: 40,
    specular: 0x070707,
    // emissive: 0x111111,
})

const Teeths = ( props:TTeethsProps ) => {
    
    const {
        modelData, 
        modelName,
    } = props
    



    return(
        <group
            name={ modelName }
        >
            {
                modelData.map((modelItem, modelIndex)=>{

                    const { name, data } = modelItem
                    let   newModelName        = name
                    const splittedName = name.split('_')



                    if(splittedName.length === 3){
                        const splittedFileName = splittedName[2].split('.')
                        newModelName = `teeth-${splittedFileName[0]}`
                    }

                    return(
                        <mesh
                            name     = { newModelName                     }
                            key      = {`${modelName}-item-${modelIndex}` }
                            geometry = { data                             }
                            material = { toothMaterial                    }
                        />
                    )
                })
            }

        </group>
    )
}

export default Teeths
